<template>
  <div>
    <v-row dense class="my-1">
      <v-col
        v-for="subj in subjectsLegend"
        :key="subj.name"
        cols="auto"
        class="d-flex align-items-center"
      >
        <div
          :style="{
            backgroundColor: subj.color,
            width: '20px',
            height: '20px',
            marginRight: '5px',
          }"
        ></div>
        <b>{{ subj.name }}</b>
      </v-col>
    </v-row>
    <div class="d-flex justify-center align-center mb-1">
      <v-btn icon @click="prevWeek">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <span class="mx-2">{{ rangeDateText() }}</span>
      <v-btn icon @click="nextWeek">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <span v-if="tutor.timezone"
        >Часовой пояс: <b>{{ tutor.timezone }}</b></span
      >
    </div>
    <div class="week">
      <div
        v-for="(day, index) in weekList"
        :key="day.day_name"
        class="week__day"
      >
        <div class="week__col week__col--head">
          {{ day.day_name }} <span>{{ day.date_short }}</span>
        </div>
        <div
          v-for="time in day.times"
          :key="`${index}_${day.day_name}_${time.time}`"
          :style="{ background: time.color }"
          class="week__col"
        >
          {{ time.time }}
          <template v-if="time.lesson">
            <v-icon
              v-if="time.lesson.teaching_type_slug === 'group'"
              small
              class="week__icon-group"
              >mdi-account-group</v-icon
            >
            <v-icon
              v-if="time.lesson.teaching_type_slug === 'individual'"
              small
              class="week__icon-group"
              >mdi-account</v-icon
            >
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api";
import moment from "moment/moment";

const DAYS = [
  { name: "Пн" },
  { name: "Вт" },
  { name: "Ср" },
  { name: "Чт" },
  { name: "Пт" },
  { name: "Сб" },
  { name: "Вс" },
];
const TIMES = [
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
];
const COLORS = [
  "#9da3e5",
  "#0cbf0c",
  "orange",
  "#cb03cb",
  "lightblue",
  "lightcoral",
  "lightpink",
  "lightsalmon",
  "lightskyblue",
  "lime",
  "Lavender",
  "Thistle",
  "Plum",
  "Violet",
  "Orchid",
  "Fuchsia",
  "MediumOrchid",
  "MediumPurple",
  "BlueViolet",
  "DarkViolet",
  "DarkOrchid",
  "DarkMagenta",
  "Indigo",
];

export default {
  name: "ScheduleTutor",
  props: {
    subjects: {
      type: Array,
      default: () => [],
    },
    userId: {
      type: Number,
      required: true,
    },
    tutor: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      weekTimes: [],
      weekDates: [],
      currentDate: moment(new Date()),
      showDate: moment(new Date()),
      schedule: [],
    };
  },
  computed: {
    subjectsLegend() {
      return this.subjects.map((subj, ind) => {
        return {
          name: subj.name,
          id: subj.id,
          color: COLORS[ind],
        };
      });
    },
    weekList() {
      return DAYS.map((day, ind) => {
        const date = this.showDate
          .isoWeekday(1)
          .weekday(ind + 1)
          .format("YYYY-MM-DD");
        const item = {
          day_name: day.name,
          date,
          date_short: moment(date).format("DD"),
          times: TIMES.map((t) => {
            const fullDate = `${date} ${t}`;
            const lesson = this.schedule.find((item) =>
              moment(item.date).isSame(fullDate)
            );
            const day = { time: t, date: fullDate };
            if (lesson) {
              day.lesson = lesson;
              day.color = this.subjectsLegend.find(
                (subj) => subj.id === lesson.subject_id
              )?.color;
              day.group = lesson.teaching_type_slug === "individual";
            }
            return day;
          }),
        };
        return item;
      });
    },
  },
  created() {
    this.getSchedule();
  },
  methods: {
    params() {
      const params = {
        year: moment(this.showDate).format("YYYY"),
        week: moment(this.showDate).isoWeek(),
      };
      if (this.tutor?.timezone) params.timezone = this.tutor.timezone;
      return params;
    },
    async getSchedule() {
      try {
        const res = await api.users.getSchedule(this.userId, this.params());
        this.schedule = res.data;
      } catch (e) {
        console.error(e);
      }
    },
    nextWeek() {
      this.showDate = this.showDate.add(7, "day");
      this.getSchedule();
    },
    prevWeek() {
      this.showDate = this.showDate.subtract(7, "day");
      this.getSchedule();
    },
    rangeDateText() {
      const from = this.showDate.isoWeekday(1).weekday(1).format("DD.MM.YYYY");
      const to = this.showDate.isoWeekday(1).weekday(7).format("DD.MM.YYYY");
      return `${from} - ${to}`;
    },
  },
};
</script>

<style scoped lang="scss">
.week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 2px;

  &__col {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 2px;
    border-radius: 4px;
    background: #eceff1;
    position: relative;
    &--head {
      flex-direction: column;
      height: auto;
      border: 1px solid;
      padding: 5px;
      font-weight: 700;
      line-height: 1.25;
      background-color: transparent;
    }
  }

  &__icon-group {
    position: absolute;
    right: 4px;
    bottom: 1px;
  }
}
</style>
