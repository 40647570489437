<template>
  <div>
    <div v-if="loader" class="text-center pa-10">
      <v-progress-circular
        :size="70"
        :width="7"
        color="accent"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-tabs v-else-if="profile">
      <v-tab>Профиль</v-tab>
      <v-tab-item eager>
        <v-row class="py-8 font-weight-medium m-0">
          <v-col cols="12" class="py-0 d-flex">
            <v-avatar width="60" height="60">
              <v-img :src="profile.avatar" alt="avatar" cover />
            </v-avatar>
            <div class="col-10 text-h6">
              {{ profile.firstname }}
              {{ profile.middlename }}
              {{ profile.lastname }}
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="col-12 pa-2">
              <span class="font-weight-bold">Почта:</span>
              {{ profile.email }}
            </div>
            <div class="col-12 pa-2">
              <span class="font-weight-bold">Телефон:</span>
              {{ profile.phone }}
            </div>
            <div class="col-12 pa-2">
              <span class="font-weight-bold">Роль:</span>
              {{ profile.role }}
            </div>
            <div class="col-12 pa-2">
              <span class="font-weight-bold">Аккаунт создан:</span>
              {{ dateCreate(profile.created_at) }}
            </div>
            <div class="col-12 pa-2">
              <span class="font-weight-bold">Последнее обновление:</span>
              {{ dateCreate(profile.updated_at) }}
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import api from "../../api";
import moment from "moment/moment";

export default {
  name: "ProfileDefault",
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      profile: {},
      loader: true,
    };
  },
  mounted() {
    this.tab = 0;
    this.getUser();
  },
  methods: {
    async getUser() {
      this.loader = true;
      try {
        const req = await api.users.getUser(this.userId);
        this.profile = req.data;
      } catch (e) {
        console.error(e);
      }
      setTimeout(() => {
        this.loader = false;
      }, 500);
    },
    dateCreate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>

<style scoped></style>
