<template>
  <div>
    <div class="d-flex justify-center align-center mb-3">
      <v-btn icon @click="prevWeek">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <span class="mx-2">{{ rangeDateText() }}</span>
      <v-btn icon @click="nextWeek">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <span v-if="tutor.timezone"
        >Часовой пояс: <b>{{ tutor.timezone }}</b></span
      >
    </div>
    <v-simple-table v-if="lessons.length" dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left ps-0">Дата</th>
            <th class="text-center">Статус</th>
            <th class="text-left">Предмет</th>
            <th class="text-left">Ученики</th>
          </tr>
        </thead>
        <tbody style="vertical-align: top">
          <tr v-for="item in lessons" :key="item.id">
            <td class="py-1 text-no-wrap ps-0" style="width: 130px">
              {{ item.date }}
            </td>
            <td class="py-1 text-no-wrap text-center" style="width: 100px">
              {{ item.status }}
            </td>
            <td class="py-1 text-no-wrap">
              {{ item.subject_name }}
              <v-icon v-if="item.teaching_type_slug === 'group'" small
                >mdi-account-group</v-icon
              >
            </td>
            <td class="py-1">
              <div v-for="student in item.students" :key="student.id">
                {{ student.firstname }} {{ student.lastname }}
                <small>
                  (статус: <b>{{ student.status }}</b
                  >)
                </small>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div v-else class="text-center error--text py-5 display-1">
      Информации нет
    </div>
  </div>
</template>

<script>
import api from "../../api";
import moment from "moment/moment";

export default {
  name: "LessonsTutor",
  props: {
    userId: {
      type: Number,
      required: true,
    },
    tutor: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      currentDate: moment(new Date()),
      showDate: moment(new Date()),
      schedule: [],
    };
  },
  computed: {
    lessons() {
      return this.schedule.filter((l) => l.students.length);
    },
  },
  created() {
    this.getSchedule();
  },
  methods: {
    params() {
      const params = {
        year: moment(this.showDate).format("YYYY"),
        week: moment(this.showDate).isoWeek(),
      };
      if (this.tutor?.timezone) params.timezone = this.tutor.timezone;
      return params;
    },
    async getSchedule() {
      try {
        const res = await api.users.getSchedule(this.userId, this.params());
        this.schedule = res.data;
      } catch (e) {
        console.error(e);
      }
    },
    nextWeek() {
      this.showDate = this.showDate.add(7, "day");
      this.getSchedule();
    },
    prevWeek() {
      this.showDate = this.showDate.subtract(7, "day");
      this.getSchedule();
    },
    rangeDateText() {
      const from = this.showDate.isoWeekday(1).weekday(1).format("DD.MM.YYYY");
      const to = this.showDate.isoWeekday(1).weekday(7).format("DD.MM.YYYY");
      return `${from} - ${to}`;
    },
  },
};
</script>

<style scoped lang="scss">
.week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 4px;

  &__col {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 4px;
    border-radius: 4px;
    background: #eceff1;
    position: relative;
    &--head {
      flex-direction: column;
      height: auto;
      border: 1px solid;
      padding: 5px;
      font-weight: 700;
      line-height: 1.25;
      background-color: transparent;
    }
  }

  &__icon-group {
    position: absolute;
    right: 4px;
    bottom: 1px;
  }
}
</style>
