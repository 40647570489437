<template>
  <div>
    <div v-if="loader" class="text-center pa-10">
      <v-progress-circular
        :size="70"
        :width="7"
        color="accent"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-tabs v-else-if="profile" v-model="tab">
      <v-tab>Профиль</v-tab>
      <v-tab>
        Образование&nbsp;
        <v-icon
          v-if="!educations.length && !educationCancel"
          small
          color="error"
          >mdi-alert</v-icon
        >
        <v-icon v-else-if="educationCancel" color="warning"
          >mdi-badge-account-alert-outline</v-icon
        >
      </v-tab>
      <v-tab
        >Репетиторство&nbsp;
        <v-icon v-if="!tutoring.length" small color="error">mdi-alert</v-icon>
      </v-tab>
      <v-tab>Расписание</v-tab>
      <v-tab>Уроки</v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item eager>
          <v-row class="py-8 font-weight-medium m-0">
            <v-col cols="12" class="py-0 d-flex">
              <v-avatar width="60" height="60">
                <v-img :src="profile.avatar" alt="avatar" cover />
              </v-avatar>
              <div class="col-10 text-h6">
                {{ profile.firstname }}
                {{ profile.lastname }}
                <v-icon
                  v-if="profile.email_verified_at !== null"
                  color="success"
                  small
                >
                  mdi-check
                </v-icon>
              </div>
            </v-col>
            <v-col cols="12" class="pb-3">
              <v-row dense>
                <v-col cols="12" md="6">
                  <div class="py-1">
                    <span class="font-weight-bold">Роль: </span>
                    <span v-html="info(profile.role)"></span>
                  </div>
                  <div class="py-1">
                    <span class="font-weight-bold">ID: </span>
                    <span v-html="info(profile.id)"></span>
                  </div>
                  <div class="py-1">
                    <span class="font-weight-bold">Страна, город: </span>
                    <span v-html="info(profile.country)"></span>
                    <span> | </span>
                    <span v-html="info(profile.city)"></span>
                  </div>
                  <div class="py-1">
                    <span class="font-weight-bold">Часовой пояс: </span>
                    <span v-html="info(profile.timezone)"></span>
                  </div>
                  <div class="py-1">
                    <span class="font-weight-bold">Дата рождения: </span>
                    <span v-html="info(profile.birthday)"></span>
                  </div>
                  <div class="py-1">
                    <span class="font-weight-bold">Пол: </span>
                    <span v-html="info(profile.gender)"></span>
                  </div>
                  <div class="py-1">
                    <span class="font-weight-bold">Опыт: </span>
                    <span v-html="info(profile.teaching_experience)"></span>
                  </div>
                  <div class="py-1">
                    <span class="font-weight-bold">Язык: </span>
                    <span v-html="info(profile.languages.join(', '))"></span>
                  </div>
                  <div class="py-1">
                    <span class="font-weight-bold">Валюта: </span>
                    <span v-html="info(profile.currency)"></span>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="py-1">
                    <span class="font-weight-bold">Email: </span>
                    <span v-html="info(profile.email)"></span>
                    <v-icon
                      v-if="profile.email_verified_at"
                      small
                      color="success"
                    >
                      mdi-check
                    </v-icon>
                  </div>
                  <div class="py-1">
                    <span class="font-weight-bold">Телефон: </span>
                    <span v-html="info(profile.phone)"></span>
                    <v-icon
                      v-if="profile.phone_verified_at"
                      small
                      color="success"
                    >
                      mdi-check
                    </v-icon>
                  </div>
                  <div class="py-1">
                    <span class="font-weight-bold">Язык интерфейса: </span>
                    <span v-html="info(profile.locale)"></span>
                  </div>
                  <div class="py-1">
                    <span class="font-weight-bold">Создан: </span>
                    <span v-html="info(dateCreate(profile.created_at))"></span>
                  </div>
                  <div class="py-1">
                    <span class="font-weight-bold">Обновлен: </span>
                    <span v-html="info(dateCreate(profile.updated_at))"></span>
                  </div>
                  <div class="py-1">
                    <span class="font-weight-bold">Видимость профиля: </span>
                    <span>{{
                      profile.is_view_profile ? "активен" : "скрыт"
                    }}</span>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="py-1">
                    <span class="font-weight-bold">О себе: </span>
                    <span v-html="info(profile.about)"></span>
                  </div>
                  <div class="py-1">
                    <span class="font-weight-bold">Видео: </span>
                    <span v-html="info(profile.video_url)"></span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item eager>
          <v-simple-table v-if="educations.length" class="py-8" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left ps-0">Образование</th>
                  <th class="text-left">Док-ты</th>
                  <th class="text-left">Дата</th>
                  <th class="text-left">Статус</th>
                </tr>
              </thead>
              <tbody style="vertical-align: top">
                <tr v-for="(edu, ind) in educations" :key="edu.id">
                  <td class="py-1 ps-0">
                    <div>{{ edu.institution }}</div>
                    <div>{{ edu.finish_study_year }} г.</div>
                    <div>{{ edu.speciality }}</div>
                    <div>{{ specs(ind) }}</div>
                    <div>{{ edu.education_degree_title }}</div>
                  </td>
                  <td class="text-no-wrap py-1">
                    <div
                      v-text="
                        edu.type === 'education' ? 'Диплом' : 'Сертификат'
                      "
                    ></div>
                    <div v-for="(file, n) in edu.files" :key="file.id">
                      <a :href="file.url" target="_blank"
                        >файл_{{ n + 1 }}({{ file.status }})</a
                      >
                    </div>
                  </td>
                  <td class="text-no-wrap py-1">
                    <v-icon>mdi-upload</v-icon>
                    {{ dateCreate(edu.uploaded_at) }}
                    <br />
                    <v-icon>mdi-update</v-icon> {{ dateCreate(edu.updated_at) }}
                  </td>
                  <td>
                    <div class="text-no-wrap">
                      {{ edu.status }}
                      <v-icon v-if="edu.verified_at" small color="success">
                        mdi-check
                      </v-icon>
                    </div>
                    <template v-if="edu.comment">
                      <small>Комментарий: </small>{{ edu.comment }}
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-else class="text-center error--text py-5 display-1">
            Информации нет
          </div>
        </v-tab-item>
        <v-tab-item eager>
          <v-simple-table v-if="tutoring.length" class="py-8">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Предмет</th>
                  <th class="text-left">Стоимость</th>
                  <th class="text-center">Базовый</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in tutoring" :key="item.id">
                  <td class="py-1">{{ item.subject_name }}</td>
                  <td class="text-no-wrap py-1">
                    <div v-for="type in item.types" :key="type.type">
                      {{ type.type }}: <b>{{ type.price }}</b>
                    </div>
                  </td>
                  <td class="text-center">
                    <v-icon v-if="item.priority === 'default'">
                      mdi-plus-thick
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-else class="text-center error--text py-5 display-1">
            Информации нет
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="pb-3">
            <schedule-tutor
              :user-id="userId"
              :subjects="subjects"
              :tutor="profile"
            ></schedule-tutor>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="py-4">
            <lessons-tutor :user-id="userId" :tutor="profile"></lessons-tutor>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import api from "../../api";
import moment from "moment";
import ScheduleTutor from "@/components/ScheduleTutor";
import LessonsTutor from "@/components/LessonsTutor";

export default {
  name: "ProfileTutor",
  components: { LessonsTutor, ScheduleTutor },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      profile: null,
      tab: null,
      loader: true,
    };
  },
  computed: {
    info() {
      return (value) => {
        return value || '<span class="error--text">Информации нет</span>';
      };
    },
    educations() {
      return this.profile.educations || [];
    },
    specs() {
      return (indexEducation) => {
        return this.profile.educations[indexEducation].specs
          .map((spec) => spec?.name?.ru)
          .join(", ");
      };
    },
    tutoring() {
      return this.profile?.tutoring || [];
    },
    subjects() {
      return this.tutoring.map((item) => {
        return {
          name: item.subject_name,
          id: item.subject_id,
        };
      });
    },
    educationCancel() {
      return this.educations.some((edu) => edu.status === "canceled");
    },
  },
  watch: {
    userId() {
      this.getUser();
    },
  },
  mounted() {
    this.tab = 0;
    this.getUser();
  },
  methods: {
    async getUser() {
      this.loader = true;
      try {
        const req = await api.users.getUser(this.userId);
        this.profile = req.data;
      } catch (e) {
        console.error(e);
      }
      setTimeout(() => {
        this.loader = false;
      }, 500);
    },
    dateCreate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>

<style scoped></style>
