<template>
  <div>
    <div class="text-h4 pt-5 mb-5">Пользователи</div>
    <UsersTable />
  </div>
</template>

<script>
import UsersTable from "@/components/UsersTable";

export default {
  name: "Users",
  components: {
    UsersTable,
  },
  created() {
    this.$store.dispatch("publicInfo/getRoles");
    this.$store.dispatch("publicInfo/getCurrencies");
  },
};
</script>

<style scoped></style>
