<template>
  <div>
    <v-row>
      <v-col cols="auto">
        <v-tabs>
          <v-tab @click="changeTab('')">All</v-tab>
          <v-tab
            v-for="role in roles"
            :key="role.name"
            @click="changeTab(role.name)"
          >
            {{ role.name }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col
        cols="auto"
        class="ms-auto align-self-center d-flex align-items-center"
      >
        <v-btn
          :loading="exportPending"
          color="secondary"
          height="40"
          class="me-2"
          @click="exports"
        >
          Экспорт
        </v-btn>
        <v-select
          v-model="exportFormat"
          :items="['pdf', 'xlsx', 'csv']"
          style="width: 100px"
          label="Формат"
          item-color="secondary"
          hide-details
          outlined
          dense
        />
      </v-col>
    </v-row>

    <v-row class="px-5 pt-4 pb-3" align="start" no-gutters>
      <v-col md="5" class="d-flex">
        <v-text-field
          label="Поиск email, телефон"
          :value="optionsTable.search"
          hide-details
          clearable
          @change="(value) => (optionsTable.search = value)"
          @click:clear="optionsTable.search = ''"
        >
        </v-text-field>
        <v-btn color="accent" elevation="0" width="40" class="ms-2 mt-auto">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="auto">
            <v-menu
              ref="menu"
              v-model="menuDates"
              :close-on-content-click="false"
              :return-value.sync="datesRange"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="datesRange.join(' ~ ')"
                  style="width: 250px"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datesRange"
                first-day-of-week="1"
                :min="datesRange.length === 1 ? datesRange[0] : ''"
                no-title
                reactive
                range
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuDates = false">
                  Отмена
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  :disabled="!datesRange[1]"
                  @click="
                    $refs.menu.save(datesRange);
                    setDate('custom');
                  "
                >
                  Ок
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12"></v-col>
          <v-col cols="auto">
            <v-btn
              small
              :color="activeDate === 'all' ? 'accent' : ''"
              @click="setDate('all')"
              >Весь период</v-btn
            >
          </v-col>
          <v-col cols="auto">
            <v-btn
              small
              :color="activeDate === 'day' ? 'accent' : ''"
              @click="setDate('day')"
              >1 день</v-btn
            >
          </v-col>
          <v-col cols="auto">
            <v-btn
              small
              :color="activeDate === 'week' ? 'accent' : ''"
              @click="setDate('week')"
              >неделя</v-btn
            >
          </v-col>
          <v-col cols="auto">
            <v-btn
              small
              :color="activeDate === 'month' ? 'accent' : ''"
              @click="setDate('month')"
              >месяц</v-btn
            >
          </v-col>
          <v-col cols="auto">
            <v-btn
              small
              :color="activeDate === 'year' ? 'accent' : ''"
              @click="setDate('year')"
              >год</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="usersList"
      :options.sync="optionsTable"
      :server-items-length="total"
      :loading="loading"
      :footer-props="{ itemsPerPageOptions: [10, 15, 20, 50, 100] }"
      class="users-table"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" scrollable max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="accent" dark class="mb-2" v-bind="attrs" v-on="on">
                Создать
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="mb-5 align-start">
                <span class="text-h5 mr-2">{{ formTitle }}</span>
                <div class="text-h5 accent--text">
                  {{ subjectCurrentName }}
                </div>
              </v-card-title>

              <v-card-text>
                <v-row class="pt-4">
                  <v-col v-if="editedIndex < 0" cols="12" class="py-0">
                    <v-select
                      v-model="editedItem.role"
                      ref="editedItem.role"
                      :rules="[rules.required]"
                      :error="errors.role ? true : false"
                      :error-messages="errors.role"
                      @focus="errors.role = null"
                      :items="roles"
                      item-text="name"
                      item-value="name"
                      label="Роль"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.firstname"
                      ref="editedItem.firstname"
                      :rules="[rules.required]"
                      :error="errors.firstname ? true : false"
                      :error-messages="errors.firstname"
                      @focus="errors.firstname = null"
                      label="Имя"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.lastname"
                      ref="editedItem.lastname"
                      :rules="[rules.required]"
                      :error="errors.lastname ? true : false"
                      :error-messages="errors.lastname"
                      @focus="errors.lastname = null"
                      label="Фамилия"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.email"
                      ref="editedItem.email"
                      :rules="[rules.required]"
                      :error="errors.email ? true : false"
                      :error-messages="errors.email"
                      @focus="errors.email = null"
                      label="Почта"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-row no-gutters>
                      <v-col>
                        <v-text-field
                          v-model="editedItem.phone"
                          ref="editedItem.phone"
                          :rules="[rules.isPhone]"
                          :error="errors.phone ? true : false"
                          :error-messages="errors.phone"
                          @focus="errors.phone = null"
                          label="Телефон"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="auto" v-if="editedItem.role === 'student'">
                        <v-btn
                          v-if="!editedItem.phone_verified_at"
                          color="accent"
                          outlined
                          class="ms-2"
                          height="40"
                          @click="phoneVerify(editedItem.id)"
                        >
                          Подтвердить
                        </v-btn>
                        <v-icon
                          v-else
                          color="success"
                          class="ms-2"
                          style="top: 6px"
                        >
                          mdi-check
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="editedIndex > -1" cols="12">
                    <v-row dense>
                      <v-col>
                        <v-select
                          v-model="editedItem.currency"
                          :items="currencies"
                          :disabled="!editedItem.can_change_currency"
                          item-text="code"
                          item-value="code"
                          label="Валюта"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-btn
                          color="secondary"
                          height="40"
                          :disabled="!editedItem.can_change_currency"
                          @click="
                            changeCurrency(editedItem.id, editedItem.currency)
                          "
                        >
                          Изменить
                        </v-btn>
                        <v-tooltip
                          v-if="!editedItem.can_change_currency"
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">
                              mdi-information-outline
                            </v-icon>
                          </template>
                          <span>У пользователя есть активности</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <template v-if="editedIndex < 0">
                    <v-col cols="12" class="py-0">
                      <v-text-field
                        v-model="editedItem.password"
                        ref="editedItem.password"
                        :rules="[rules.required]"
                        :error="errors.password ? true : false"
                        :error-messages="errors.password"
                        @focus="errors.password = null"
                        label="Пароль"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <v-text-field
                        v-model="editedItem.password_confirmation"
                        ref="editedItem.password_confirmation"
                        :rules="[
                          rules.required,
                          rules.sameAs(
                            editedItem.password,
                            editedItem.password_confirmation,
                            'Пароли не совпадают'
                          ),
                        ]"
                        :error="errors.password ? true : false"
                        :error-messages="errors.password"
                        label="Повторите пароль"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-card-text>

              <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn color="accent" outlined @click="close"> Отмена </v-btn>
                <v-btn color="accent" @click="save">
                  <template v-if="editedIndex > -1"> Изменить </template>
                  <template v-else> Сохранить </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDetails" max-width="900px" eager>
            <v-card>
              <v-card-text v-if="detailsId">
                <profile-tutor
                  v-if="editedItem.role === 'tutor'"
                  :user-id="detailsId"
                ></profile-tutor>
                <profile-student
                  v-else-if="editedItem.role === 'student'"
                  :user-id="detailsId"
                ></profile-student>
                <profile-default v-else :user-id="detailsId"></profile-default>
                <div class="d-flex justify-end">
                  <v-btn color="accent" outlined @click="close">
                    Закрыть
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <template>
              <v-card>
                <v-card-title
                  class="text-h5 text-center justify-center flex-column mb-5"
                  >Удалить Пользователя<br />
                  <span class="accent--text">{{ subjectCurrentName }}</span>
                </v-card-title>

                <v-card-actions class="pb-4">
                  <v-spacer></v-spacer>
                  <v-btn color="accent" outlined @click="closeDelete"
                    >Отмена</v-btn
                  >
                  <v-btn color="accent" @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.email="{ item }">
        {{ item.email }}
        <v-icon
          v-if="item.email_verified_at !== null"
          color="success"
          class="ml-1"
          small
        >
          mdi-check
        </v-icon>
      </template>
      <template v-slot:item.country_code="{ value }">
        <country-flag
          :country="value || '1'"
          :class="{ 'grey lighten-2': !value }"
          class="ma-0 elevation-3"
        />
      </template>
      <template v-slot:item.role="{ item, value }">
        <div class="d-flex align-center">
          <v-icon
            v-if="item.is_view_profile && optionsTable.role === 'tutor'"
            color="accent"
            class="ms-n8 me-2"
            >mdi-account-eye</v-icon
          >
          <div class="text-no-wrap">{{ value }}</div>
        </div>
      </template>
      <template v-slot:item.created_at="{ item }">
        <div class="text-no-wrap">{{ dateCreate(item.created_at) }}</div>
      </template>
      <template v-slot:item.is_test="{ item }">
        <div class="text-no-wrap">
          <v-checkbox
            v-model="item.is_test"
            @change="toggleTestStatus(item.id, item.is_test)"
            color="secondary"
          ></v-checkbox>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-no-wrap">
          <v-icon small class="mr-2" @click="itemDetails(item)">
            mdi-information-outline
          </v-icon>
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import api from "../../api";
import moment from "moment";
import { mapGetters } from "vuex";
import rules from "@/mixins/rules";
import ProfileTutor from "@/components/ProfileTutor";
import ProfileStudent from "@/components/ProfileStudent";
import ProfileDefault from "@/components/ProfileDefault";
import CountryFlag from "vue-country-flag";

export default {
  name: "UsersTable",
  mixins: [rules],
  components: { ProfileTutor, ProfileStudent, ProfileDefault, CountryFlag },
  data() {
    return {
      dialog: false,
      dialogDetails: false,
      dialogDelete: false,
      headers: [
        {
          text: "Имя",
          align: "start",
          sortable: true,
          value: "firstname",
        },
        {
          text: "Фамилия",
          sortable: true,
          value: "lastname",
        },
        {
          text: "Почта",
          sortable: true,
          value: "email",
        },
        {
          text: "Роль",
          value: "role",
          sortable: false,
        },
        {
          text: "Страна",
          value: "country_code",
          align: "center",
          sortable: false,
        },
        {
          text: "Часовой пояс",
          value: "timezone",
          sortable: false,
        },
        {
          text: "Создан",
          value: "created_at",
          sortable: true,
        },
        {
          text: "Тест",
          value: "is_test",
          sortable: false,
        },
        {
          text: "Действия",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
      usersList: [],
      editedIndex: -1,
      editedItem: null,
      updateItem: {
        email: "",
        firstname: "",
        lastname: "",
        phone: "",
      },
      optionsTable: {
        search: "",
        role: "",
        sortBy: ["created_at"],
        sortDesc: [true],
      },
      total: 0,
      errors: {},
      loading: true,
      updateInterval: null,
      exportFormat: "pdf",
      menuDates: false,
      activeDate: "all",
      datesRange: [],
      exportPending: false,
    };
  },

  computed: {
    ...mapGetters({
      updateTime: "updateTime",
      roles: "publicInfo/roles",
      currencies: "publicInfo/currencies",
    }),
    formTitle() {
      return this.editedIndex === -1
        ? "Новый пользователь"
        : "Изменить пользователя";
    },
    subjectCurrentName() {
      return this.editedIndex > -1
        ? this.usersList[this.editedIndex].email
        : "";
    },
    detailsId() {
      return this.editedItem?.id;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDetails(val) {
      val || this.close();
    },
    optionsTable: {
      async handler() {
        await this.$nextTick();
        await this.getUsers();
      },
      deep: true,
    },
  },
  created() {
    this.editedItem = { ...this.createItem() };
    this.updateInterval = setInterval(() => this.getUsers(), this.updateTime);
  },
  beforeDestroy() {
    this.updateInterval = clearInterval(this.updateInterval);
  },
  methods: {
    async editItem(item) {
      this.editedIndex = this.usersList.indexOf(item);
      const loadItem = await this.getUser(item.id);
      this.editedItem = { ...loadItem };
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.usersList.indexOf(item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.deleteUser(this.usersList[this.editedIndex].id);
      this.getUsers();
      this.closeDelete();
    },

    async itemDetails(item) {
      this.editedIndex = this.usersList.indexOf(item);
      this.editedItem = this.usersList[this.editedIndex];
      await this.$nextTick();
      this.dialogDetails = true;
    },

    async close() {
      this.dialog = false;
      this.dialogDetails = false;
      this.errors = {};
      this.$nextTick(() => {
        this.editedItem = { ...this.createItem() };
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.createItem() };
        this.editedIndex = -1;
      });
    },

    async save(close = true) {
      if (this.editedIndex > -1) {
        let formHasErrors = this.validationObject(
          this.updateItem,
          "editedItem"
        );
        if (formHasErrors) {
          this.$store.dispatch("showSnackbar", {
            msg: "Заполните обязательные поля",
            color: "error",
            show: true,
          });
          return;
        }
        await this.editUser(close);
      } else {
        let formHasErrors = this.validationObject(
          this.editedItem,
          "editedItem"
        );
        if (formHasErrors) {
          this.$store.dispatch("showSnackbar", {
            msg: "Заполните обязательные поля",
            color: "error",
            show: true,
          });
          return;
        }
        await this.addUsers();
      }
      this.getUsers();
    },

    async getUsers() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage, search, role } =
        this.optionsTable;
      try {
        const req = await api.users.getUsers({
          sortBy: sortBy && sortBy.length > 0 ? sortBy[0] : null,
          sortDesc: sortDesc && sortDesc.length > 0 ? sortDesc[0] : null,
          page,
          itemsPerPage,
          search,
          role,
          from: this.datesRange[0],
          to: this.datesRange[1],
        });
        if (req.data) {
          this.usersList = req.data.items;
          this.total = req.data.total;
        }
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },

    async addUsers() {
      try {
        await api.users.sendUser(this.editedItem);
        this.$store.dispatch("showSnackbar", {
          msg: `Пользователь "${this.editedItem.email}" создан`,
          color: "success",
          show: true,
        });
        this.close();
        this.getUsers();
      } catch (e) {
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка создания",
            color: "error",
            show: true,
          });
        }
      }
    },

    async editUser(close = true) {
      try {
        let item = { ...this.editedItem };
        delete item.id;
        await api.users.editUser(this.editedItem.id, item);
        this.$store.dispatch("showSnackbar", {
          msg: `Пользователь "${this.editedItem.email}" изменен`,
          color: "success",
          show: true,
        });
        if (close) this.close();
      } catch (e) {
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка редактирования",
            color: "error",
            show: true,
          });
        }
      }
    },

    async getUser(id) {
      try {
        const req = await api.users.getUser(id);
        return req.data;
      } catch (e) {
        console.error(e);
        return false;
      }
    },

    async deleteUser(id) {
      try {
        const nameDelete = this.usersList[this.editedIndex].email;
        await api.users.deleteUser(id);
        this.$store.dispatch("showSnackbar", {
          msg: `Пользователь "${nameDelete}" удален`,
          color: "success",
          show: true,
        });
      } catch (e) {
        console.error(e);
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка удаления",
          color: "error",
          show: true,
        });
      }
    },
    createItem() {
      return {
        email: "",
        role: "",
        firstname: "",
        lastname: "",
        phone: "",
        password: "",
        password_confirmation: "",
      };
    },
    dateCreate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm");
    },
    async phoneVerify(id) {
      try {
        await this.save(false);
        await api.users.phoneVerify(id);
        const loadItem = await this.getUser(id);
        this.editedItem = { ...loadItem };
      } catch (e) {
        console.error(e);
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка подтверждения телефона",
          color: "error",
          show: true,
        });
      }
    },
    changeTab(role) {
      this.optionsTable.role = role;
      this.optionsTable.search = "";
    },
    async exports() {
      this.exportPending = true;
      const { data } = await api.users.download({
        role: this.optionsTable.role,
        sortBy: "created_at",
        sortDesc: true,
        as: this.exportFormat,
        from: this.datesRange[0],
        to: this.datesRange[1],
      });
      if (!data) {
        this.exportPending = false;
        return;
      }
      const typeFile = {
        pdf: "application/pdf",
        csv: "text/csv",
        xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      };
      const file = new Blob([data], {
        type: typeFile[this.exportFormat],
      });
      const href = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `tema_users_${moment().format("YYYY_MM_DD_HHmmss")}.${
          this.exportFormat
        }`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(href);
      this.exportPending = false;
    },
    async setDate(value) {
      this.activeDate = value;
      switch (value) {
        case "day":
          this.datesRange = [
            moment().format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
          ];
          break;
        case "week":
          this.datesRange = [
            moment().isoWeekday(1).weekday(1).format("YYYY-MM-DD"),
            moment().isoWeekday(1).weekday(7).format("YYYY-MM-DD"),
          ];
          break;
        case "month":
          this.datesRange = [
            moment().startOf("month").format("YYYY-MM-DD"),
            moment().endOf("month").format("YYYY-MM-DD"),
          ];
          break;
        case "year":
          this.datesRange = [
            moment().startOf("year").format("YYYY-MM-DD"),
            moment().endOf("year").format("YYYY-MM-DD"),
          ];
          break;
        case "custom":
          break;
        default:
          this.datesRange = [];
      }
      this.getUsers();
    },
    async changeCurrency(id, currency) {
      try {
        await api.users.setCurrency(id, currency);
        this.$store.dispatch("showSnackbar", {
          msg: "Валюта изменена",
          color: "success",
          show: true,
        });
      } catch (e) {
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка ",
          color: "error",
          show: true,
        });
      }
    },
    async toggleTestStatus(id, status) {
      try {
        await api.users.setTestStatus(id, { test_status: status ? 1 : 0 });
      } catch (e) {
        this.usersList.find((e) => e.id === id).is_test = !status;
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка ",
          color: "error",
          show: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
