<template>
  <div>
    <div v-if="loader" class="text-center pa-10">
      <v-progress-circular
        :size="70"
        :width="7"
        color="accent"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-tabs v-else-if="profile" v-model="tab">
      <v-tab>Профиль</v-tab>
      <v-tab>Уроки</v-tab>
      <v-tab>Объявления</v-tab>
      <v-tab-item eager>
        <v-row class="py-8 font-weight-medium m-0">
          <v-col cols="12" class="py-0 d-flex">
            <v-avatar width="60" height="60">
              <v-img :src="profile.avatar" alt="avatar" cover />
            </v-avatar>
            <div class="col-10 text-h6">
              {{ profile.firstname }}
              {{ profile.lastname }}
              <v-icon
                v-if="profile.email_verified_at !== null"
                color="success"
                small
              >
                mdi-check
              </v-icon>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <v-row dense>
              <v-col cols="12" md="6">
                <div class="py-1">
                  <span class="font-weight-bold">Роль: </span>
                  <span v-html="info(profile.role)"></span>
                </div>
                <div class="py-1">
                  <span class="font-weight-bold">ID: </span>
                  <span v-html="info(profile.id)"></span>
                </div>
                <div class="py-1">
                  <span class="font-weight-bold">Страна, город: </span>
                  <span v-html="info(profile.country)"></span>
                  <span> | </span>
                  <span v-html="info(profile.city)"></span>
                </div>
                <div class="py-1">
                  <span class="font-weight-bold">Часовой пояс: </span>
                  <span v-html="info(profile.timezone)"></span>
                </div>
                <div class="py-1">
                  <span class="font-weight-bold">Валюта: </span>
                  <span v-html="info(profile.currency)"></span>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="py-1">
                  <span class="font-weight-bold">Email: </span>
                  <span v-html="info(profile.email)"></span>
                  <v-icon
                    v-if="profile.email_verified_at"
                    small
                    color="success"
                  >
                    mdi-check
                  </v-icon>
                </div>
                <div class="py-1">
                  <span class="font-weight-bold">Телефон: </span>
                  <span v-html="info(profile.phone)"></span>
                  <v-icon
                    v-if="profile.phone_verified_at"
                    small
                    color="success"
                  >
                    mdi-check
                  </v-icon>
                </div>
                <div class="py-1">
                  <span class="font-weight-bold">Пол: </span>
                  <span v-html="info(profile.gender)"></span>
                </div>
                <div class="py-1">
                  <span class="font-weight-bold">День рождения: </span>
                  <span v-html="info(profile.birthday)"></span>
                </div>
                <div class="py-1">
                  <span class="font-weight-bold">Создан: </span>
                  <span v-html="info(dateCreate(profile.created_at))"></span>
                </div>
                <div class="py-1">
                  <span class="font-weight-bold">Обновлен: </span>
                  <span v-html="info(dateCreate(profile.updated_at))"></span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item eager>
        <div class="display-1 text-center py-4">В разработке</div>
      </v-tab-item>
      <v-tab-item eager>
        <div class="display-1 text-center py-4">В разработке</div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import api from "../../api";
import moment from "moment";

export default {
  name: "ProfileStudent",
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      profile: null,
      tab: 0,
      loader: true,
    };
  },
  computed: {
    info() {
      return (value) => {
        return value || '<span class="error--text">Информации нет</span>';
      };
    },
  },
  mounted() {
    this.tab = 0;
    this.getUser();
  },
  methods: {
    async getUser() {
      this.loader = true;
      try {
        const req = await api.users.getUser(this.userId);
        this.profile = req.data;
      } catch (e) {
        console.error(e);
      }
      setTimeout(() => {
        this.loader = false;
      }, 500);
    },
    dateCreate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>

<style scoped></style>
